
$(document).ready(function () {
    new ConfigRouteParser();
});

export class ConfigRouteParser {

    private initialised: boolean = false;

    constructor() {
        this.init();
    }

    private init = (): void => {
        if (!this.initialised) {
            this.parseLinks();
            this.initialised = true;
        }
    }

    private parseLinks = (): void => {

        // append links to all tag-helper-generated lanugage pack strings
        // refering back to the appropriate config provider editor screen
        let targets = $("span[data-configuration-provider-route]");

        targets.each((i, elem) => {
            elem.innerHTML += "<a href=\"" + elem.dataset.configurationProviderRoute + "\"><span data-bs-toggle=\"tooltip\" title=\"" + elem.dataset.configurationProviderTooltip + "\" aria-hidden=\"true\" class=\"fas fa-edit\" style=\"margin-left:10px\"></span></a>";
            elem.setAttribute("ConfigParsed", "true");
        })
    }
}